// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

function CalculateEstimate(service, squareFootage, hasPets) {
	const MIN_COST = 40;
	let petCost = 0.02;
	let baseCost = -1;

	switch (service) {
		case "Standard Cleaning":
			baseCost = 0.1;
			break;
		case "Deep Cleaning":
			baseCost = 0.15;
			break;
		case "Move In/Out Cleaning":
			baseCost = 0.25;
			petCost = 0.03;
			break;
		default:
			// Error State	
			return "N/A";
	}

	if (hasPets) {
		baseCost += petCost;
	}

	let cost = squareFootage * baseCost;
	if (cost < MIN_COST) {
		cost = MIN_COST;
	}
	cost = Math.ceil(cost);

	// Format the price above to USD using the locale, style, and currency.
	let USDollar = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return `${USDollar.format(cost)}`;
}

function showError(message) {
	const errorAlert = document.getElementById('errorAlert');
	errorAlert.classList.remove('d-none');
	errorAlert.textContent = message;
	return;
}

function showSuccess(message) {
	const successAlert = document.getElementById('successAlert');
	successAlert.classList.remove('d-none');
	successAlert.textContent = message;
	return;
}

// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
	'use strict';
	window.addEventListener('load', function () {
		document.getElementById('estimateForm').addEventListener('submit', function (event) {
			event.preventDefault(); // Prevent form submission

			// Get form inputs
			const squareFootage = parseFloat(document.getElementById('estimateForm-SquareFootage').value);
			const service = document.getElementById('estimateForm-service').value;
			const hasPets = document.getElementById('estimateForm-pets').checked;

			// Perform validation
			if (!squareFootage || squareFootage <= 0) {
				showError('Please enter a valid square footage.');
				return;
			}
			if (!service) {
				showError('Please select a service.');
				return;
			}

			// Call function to calculate estimate
			const estimate = CalculateEstimate(service, squareFootage, hasPets);

			// Display the result in the success alert
			showSuccess(`Estimated cost: ${estimate}`);
		});
	});
})();
